:root {
    --jbutton-color: #298BA8; 
    --jactive-color: #007aff;
    --jsafe-area-top: env(safe-area-inset-top);
    --jsafe-area-bottom: env(safe-area-inset-bottom);
}

[data-visible="false"], .row[data-visible="false"] {
    display: none;
}


div[data-before]:before {
    content: attr(data-before);
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.jreadonly {
    pointer-events: none;
}

.jdragging {
    opacity:0.2;
    filter: alpha(opacity=20);
}

.jupload.input {
    position: relative;
    box-sizing: border-box;
    background-size: initial;
    height: 33px;
    min-height: initial;
    padding: 6px;
    padding-right: 30px;
}

.jupload.input:before {
    content: "save";
    font-size: 18px;
    font-family: "Material Icons";
    color: #000;
    position: absolute;
    right: 5px;
}

.jupload:empty:before {
    z-index: 0;
}

.jupload img {
    width: 100%;
} 

.jupload.input img {
    width: initial;
    max-width: 100%;
    height: 100%;
}

.jupload[data-multiple] {
    padding: 10px;
}

.jupload[data-multiple] img {
    height: 70px;
    width: 100px;
    object-fit: cover;
    margin-right: 5px;
    margin-bottom: 5px;
}

.jupload {
    position: relative;
    border: 1px dotted #eee;
    cursor: pointer;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    min-height: 180px;
}

.jupload:not(.input):empty:before {
    content: "\e2c3";
    font-family: "Material Icons";
    font-size: 90px;
    color: #eee;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
}

.jupload-item {
    padding-right: 22px;
    border-radius: 1px;
    display: inline-block;
    position: relative;
}

.jphoto {
    position: relative;
    border: 1px dotted #eee;
    cursor: pointer;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.jphoto:empty:before {
    content: "\e2c3";
    font-family: "Material Icons";
    font-size: 90px;
    color: #eee;
    width: 100%;
    height: 100%;
    text-align: center;
}

.jremove {
    opacity: 0.2;
    filter: alpha(opacity=20);
}

.round img {
    border-radius: 1000px;
}

.jtooltip {
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 5;

    font-family: initial;
    font-size: 12px;
    color: #000;
    background-color: #fff;
    border: 1px solid black;
    padding: 8px;
    margin: 10px;

    display: block;
    animation: jfadeIn 0.5s;
    pointer-events: none;
}

.jtooltip:empty {
    display: none;
}

@keyframes jfadeIn {
     0% {
          opacity: 0;
     }
     100% {
          opacity: 1;
     }
}

.jpanel {
    position: absolute;
    box-sizing: border-box;
    z-index: 10;
}

.jpanel:focus {
    outline: 2px solid black;
    outline-offset: -1px;
}

.jpanel-action {
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: white;
    border:1px solid black;
    box-sizing: border-box;
    pointer-events: auto;
    z-index: 1000;
}

.jpanel-action[data-position="n-resize"] {
    top: -4px;
    left: calc(50% - 4px);
}

.jpanel-action[data-position="ne-resize"] {
    top: -4px;
    left: calc(100% - 4px);
}

.jpanel-action[data-position="e-resize"] {
    top: calc(50% - 4px);
    left: calc(100% - 4px);
}

.jpanel-action[data-position="se-resize"] {
    top: calc(100% - 4px);
    left: calc(100% - 4px);
}

.jpanel-action[data-position="s-resize"] {
    top: calc(100% - 4px);
    left: calc(50% - 4px);
}

.jpanel-action[data-position="sw-resize"] {
    top: calc(100% - 4px);
    left: -4px;
}

.jpanel-action[data-position="w-resize"] {
    top: calc(50% - 4px);
    left: -4px;
}

.jpanel-action[data-position="nw-resize"] {
    top: -4px;
    left: -4px;
}

.jpanel-action[data-position="nw-resize"] {
    top: -4px;
    left: -4px;
}

.jpanel-action[data-action="rotate"] {
    top: -50px;
    left: calc(50% - 12px);
    width: 24px;
    height: 24px;
    border: 0;
    cursor: move;
    background-color: transparent;
}

.jpanel-action[data-action="rotate"]:before {
    content: 'refresh';
    font-family: 'Material Icons';
    font-size: 24px;
}

.jpanel-action[data-action="rotate"]:after {
    position: absolute;
    content: '';
    bottom: -22px;
    left: calc(50%);
    width: 1px;
    height: 28px;
    background-color: black;
}

/** Loading */
.jloading {
    position:fixed;
    z-index:10001;
    width:100%;
    left:0;
    right:0;
    top:0;
    bottom:0;
    background-color: rgba(0,0,0,0.7);
}

.jloading::after {
    content:'';
    display:block;
    margin:0 auto;
    margin-top:50vh;
    width:40px;
    height:40px;
    border-style:solid;
    border-color:white;
    border-top-color:transparent;
    border-width:4px;
    border-radius:50%;
    -webkit-animation: jspin .8s linear infinite;
    animation: jspin .8s linear infinite;
}

.jloading.jspin {
    background-color:transparent;
}

.jloading.jspin::after {
    margin:0 auto;
    margin-top:80px;
    border-color:#aaa;
    border-top-color:transparent;
}

/** Animations **/
.jfade-in {
    animation: jfade-in 2s forwards;
}

.jfade-out {
    animation: jfade-out 1s forwards;
}

.jslide-left-in {
    position: relative;
    animation: jslide-left-in 0.4s forwards;
}

.jslide-left-out {
    position: relative;
    animation: jslide-left-out 0.4s forwards;
}

.jslide-right-in {
    position: relative;
    animation: jslide-right-in 0.4s forwards;
}

.jslide-right-out {
    position: relative;
    animation: jslide-right-out 0.4s forwards;
}

.jslide-top-in {
    position: relative;
    animation: jslide-top-in 0.4s forwards;
}

.jslide-top-out {
    position: relative;
    animation: jslide-top-out 0.2s forwards;
}

.jslide-bottom-in {
    position: relative;
    animation: jslide-bottom-in 0.4s forwards;
}

.jslide-bottom-out {
    position: relative;
    animation: jslide-bottom-out 0.1s forwards;
}

.jslide-left-in > div {
    -webkit-transform: translateZ(0px);
    -webkit-transform: translate3d(0,0,0);
}

.jslide-left-out > div {
    -webkit-transform: translateZ(0px);
    -webkit-transform: translate3d(0,0,0);
}

.jslide-right-in > div {
    -webkit-transform: translateZ(0px);
    -webkit-transform: translate3d(0,0,0);
}

.jslide-right-out > div {
    -webkit-transform: translateZ(0px);
    -webkit-transform: translate3d(0,0,0);
}

.jspin {
    animation: jspin 2s infinite linear;
}

/** Fadein and Fadeout **/
@keyframes jfade-in {
    0% { opacity: 0; }
    100% { opacity: 100; }
}

@-webkit-keyframes jfade-in {
    0% { opacity: 0; }
    100% { opacity: 100; }
}

@keyframes jfade-out {
    0% { opacity: 100; }
    100% { opacity: 0; }
}

@-webkit-keyframes jfade-out {
    0% { opacity: 100; }
    100% { opacity: 0; }
}

/** Keyframes Left to Right **/
@keyframes jslide-left-in {
    0% { left: -100%; }
    100% { left: 0%; }
}

@-webkit-keyframes jslide-left-in {
    0% { left: -100%; }
    100% { left: 0%; }
}
    
@keyframes jslide-left-out {
    0% { left: 0%; }
    100% { left: -100%; }
}

@-webkit-keyframes jslide-left-out {
    0% { left: 0%; }
    100% { left: -100%; }
}

/** Keyframes Right to Left **/
@keyframes jslide-right-in {
    0% { left: 100%; }
    100% { left: 0%; }
}

@-webkit-keyframes jslide-right-in
{
    0% { left: 100%; }
    100% { left: 0%; }
}
    
@keyframes jslide-right-out {
    0% { left: 0%; }
    100% { left: 100%; }
}

@-webkit-keyframes jslide-right-out {
    0% { left: 0%; }
    100% { left: 100%; }
}

/** Keyframes Top to Bottom **/
@keyframes jslide-top-in {
    0% { transform: translateY(-100%); }
    100% { transform: translateY(0%); }
}

@-webkit-keyframes jslide-top-in {
    0% { transform: translateY(-100%); }
    100% { -webkit-transform: translateY(0%); }
}
    
@keyframes jslide-top-out {
    0% { transform: translateY(0%); }
    100% { transform: translateY(-100%); }
}

@-webkit-keyframes jslide-top-out {
    0% { -webkit-transform: translateY(0%); }
    100% { -webkit-transform: translateY(-100%); }
}

/** Keyframes Bottom to Top **/
@keyframes jslide-bottom-in {
    0% { transform: translateY(100%); }
    100% { transform: translateY(0%); }
}

@-webkit-keyframes jslide-bottom-in {
    0% { transform: translateY(100%); }
    100% { -webkit-transform: translateY(0%); }
}
    
@keyframes jslide-bottom-out {
    0% { transform: translateY(0%); }
    100% { transform: translateY(100%); }
}

@-webkit-keyframes jslide-bottom-out {
    0% { -webkit-transform: translateY(0%); }
    100% { -webkit-transform: translateY(100%); }
}

@-webkit-keyframes jspin {
    from {
        -webkit-transform:rotate(0deg);
    }
    to {
        -webkit-transform:rotate(359deg);
    }
}

@keyframes jspin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(359deg);
    }
}
.jcalendar {
    position:absolute;
    z-index:9000;
    display:none;
    box-sizing:border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
    min-width:280px;
}

.jcalendar.jcalendar-focus {
    display:block;
}

.jcalendar .jcalendar-backdrop {
    position:fixed;
    top:0px;
    left:0px;
    z-index:9000;
    min-width:100%;
    min-height:100%;
    background-color:rgba(0,0,0,0.5);
    border:0px;
    padding:0px;
    display:none;
}

.jcalendar .jcalendar-container {
    position:relative;
    box-sizing:border-box;
}

.jcalendar .jcalendar-content {
    position:absolute;
    z-index:9001;
    -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
    -moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
    box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
    background-color:#fff;
}

.jcalendar-header {
    text-align:center;
}

.jcalendar-header span {
    margin-right:4px;
    font-size:1.1em;
    font-weight:bold;
}

.jcalendar-prev {
    cursor:pointer;
    background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2724%27 height=%2724%27 viewBox=%270 0 24 24%27%3E%3Cpath d=%27M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z%27 fill=%27%23000%27 /%3E%3Cpath fill=%27none%27 d=%27M0 0h24v24H0V0z%27/%3E%3C/svg%3E");
    background-position:center;
    background-repeat:no-repeat;
}

.jcalendar-next {
    cursor:pointer;
    background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2724%27 height=%2724%27 viewBox=%270 0 24 24%27%3E%3Cpath d=%27M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z%27 fill=%27%23000%27 /%3E%3Cpath fill=%27none%27 d=%27M0 0h24v24H0V0z%27/%3E%3C/svg%3E");
    background-position:center;
    background-repeat:no-repeat;
}

.jcalendar-weekday {
    font-weight: 600;
    background-color: #fcfcfc;
    padding: 14px;
}

.jcalendar-table {
    padding: 10px;
}

.jcalendar-table > table {
    width:100%;
    background-color:#fff;
}

.jcalendar-table > table > thead {
    cursor: pointer;
}

.jcalendar-table thead td {
    padding: 10px;
    height: 40px;
}

.jcalendar-table > table > tbody > tr {
    height: 34px;
}

.jcalendar-table > table > tbody td {
    box-sizing:border-box;
    cursor:pointer;
    padding:9px;
    font-size:0.9em;
}

.jcalendar-table tfoot td {
    padding:10px;
}

.jcalendar-months td, .jcalendar-years td {
    height:24px;
} 

.jcalendar-input {
    padding-right:18px;
    background-image:url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2716%27 height=%2716%27 viewBox=%270 0 24 24%27 fill=%27gray%27%3E%3Cpath d=%27M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z%27/%3E%3Cpath fill=%27none%27 d=%27M0 0h24v24H0z%27/%3E%3C/svg%3E");
    background-position:top 50% right 5px;
    background-repeat:no-repeat;
    box-sizing: border-box;
}

.jcalendar-done {
    -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
    -moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
    box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
    background-color:#fff;
}

.jcalendar-update {
    border:1px solid #ccc;
    background-color:#fff;
    border-radius:4px;
    padding:5px;
    width:100%;
}

.jcalendar-container select {
    width:55px;
    display:inline-block;
    border:0px;
    padding:4px;
    text-align:center;
    font-size:1.1em;
    user-select:none;
    margin-right:10px;
}

.jcalendar-container select:first-child {
    margin-right:2px;
}

.jcalendar-selected {
    background-color:#eee;
}

.jcalendar-reset, .jcalendar-confirm {
    text-transform:uppercase;
    cursor:pointer;
    color: var(--jactive-color);
}

.jcalendar-controls {
    padding:15px;

    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    vertical-align:middle;

    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -webkit-flex-flow: row wrap;
    justify-content: space-between;
    align-items:center;
}

.jcalendar-controls div {
    font-weight:bold;
}

.jcalendar-fullsize  {
    position:fixed;
    width:100%;
    top:0px;
    left:0px;
}

.jcalendar-fullsize .jcalendar-content
{
    position:fixed;
    width:100%;
    left:0px;
    bottom:0px;
}

.jcalendar-focus.jcalendar-fullsize .jcalendar-backdrop {
    display:block;
}

.jcalendar-sunday {
    color: red;
}
.jcalendar-disabled {
    color: #ccc;
}

.jcalendar-time {
    display:flex;
}

.jcalendar_warning {
    color: red;
}

.jcalendar-hide-controls .jcalendar-controls {
    display: none;
}

.jcolor {
    display: none;
    outline: none;
    position: absolute;
}

.jcolor-input {
    padding-right: 24px !important;
    background: url("data:image/svg+xml,%0A%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27black%27 width=%2718px%27 height=%2718px%27%3E%3Cpath d=%27M0 0h24v24H0z%27 fill=%27none%27/%3E%3Cpath d=%27M12 3c-4.97 0-9 4.03-9 9s4.03 9 9 9c.83 0 1.5-.67 1.5-1.5 0-.39-.15-.74-.39-1.01-.23-.26-.38-.61-.38-.99 0-.83.67-1.5 1.5-1.5H16c2.76 0 5-2.24 5-5 0-4.42-4.03-8-9-8zm-5.5 9c-.83 0-1.5-.67-1.5-1.5S5.67 9 6.5 9 8 9.67 8 10.5 7.33 12 6.5 12zm3-4C8.67 8 8 7.33 8 6.5S8.67 5 9.5 5s1.5.67 1.5 1.5S10.33 8 9.5 8zm5 0c-.83 0-1.5-.67-1.5-1.5S13.67 5 14.5 5s1.5.67 1.5 1.5S15.33 8 14.5 8zm3 4c-.83 0-1.5-.67-1.5-1.5S16.67 9 17.5 9s1.5.67 1.5 1.5-.67 1.5-1.5 1.5z%27/%3E%3C/svg%3E") top 50% right 4px no-repeat, content-box;
    box-sizing: border-box;
}

.jcolor-content {
    position: absolute;
    z-index: 9000;
    user-select: none;
    -webkit-font-smoothing: antialiased;
    font-size: .875rem;
    letter-spacing: .2px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
    box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
    background-color:#fff;
    box-sizing: border-box;
    min-width: 260px;
}

.jmodal .jcolor-content {
    position: fixed;
}

.jcolor-controls {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid #eee;
    margin-bottom: 5px;
}

.jcolor-controls div {
    flex: 1;
    font-size: 1em;
    color: var(--jactive-color);
    text-transform: uppercase;
    font-weight: bold;
    box-sizing: border-box;
}

.jcolor-content table {
    border-collapse: collapse;
    box-sizing: border-box;
}

.jcolor-focus {
    display:block;
}

.jcolor table {
    width:100%;
    height:100%;
    min-height: 160px;
}

.jcolor td {
    padding: 7px;
}

.jcolor-selected {
    background-repeat:no-repeat;
    background-size: 16px;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2724%27 height=%2724%27 viewBox=%270 0 24 24%27%3E%3Cpath d=%27M0 0h24v24H0z%27 fill=%27none%27/%3E%3Cpath d=%27M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z%27 fill=%27white%27/%3E%3C/svg%3E");
}

.jcolor-fullscreen {
    position: fixed;
    left: 0px;
    bottom: 0px;
    width:100%;
    max-height: 290px;
    border-radius: 0px;
    box-sizing: border-box;
}

.jcolor-fullscreen .jcolor-controls {
    padding: 15px;
    -webkit-box-shadow: 1px 0px 1px 0px rgba(0,0,0,0.39);
    -moz-box-shadow: 1px 0px 1px 0px rgba(0,0,0,0.39);
    box-shadow: 1px 0px 1px 0px rgba(0,0,0,0.39);
}

.jcolor-reset {
    text-align: left;
}

.jcolor-close {
    text-align: right;
}

.jcolor-backdrop {
    position: fixed;
    top: 0px;
    left: 0px;
    min-width: 100%;
    min-height: 100%;
    background-color: rgba(0,0,0,0.5);
    border: 0px;
    padding: 0px;
    z-index: 8000;
    display: none;
    
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.jcolor-content .jtabs-content {
    padding: 7px;
}

.jcolor-grid tr:first-child > td:first-child {
    border-top-left-radius: 3px;
}

.jcolor-grid tr:first-child > td:last-child {
    border-top-right-radius: 3px;
}

.jcolor-grid tr:last-child > td:first-child {
    border-bottom-left-radius: 3px;
}

.jcolor-grid tr:last-child > td:last-child {
    border-bottom-right-radius: 3px;
}

.jcolor-hsl {
    box-sizing: border-box;
}

.jcolor-hsl > div {
    height: 100%;
    position: relative;
}

.jcolor-hsl canvas {
    display: block;
    border-radius: 4px;
    -webkit-user-drag: none;
}

.jcolor-point {
    height: 5px;
    width: 5px;
    background-color: #000;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}

.jcolor-sliders {
    padding: 10px 20px 10px 10px;
}

.jcolor-sliders input {
    -webkit-appearance: none;

    height: 12px;
    width: 80%;

    background: #d3d3d3;
    opacity: 1;

    border-radius: 30px;
    outline: none;
}

.jcolor-sliders-input-subcontainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.jcolor-sliders-input-container {
    margin-top: 4px;
    line-height: 0.8em;
    text-align: left;
}

.jcolor-sliders-input-container > label {
    font-size: 10px;
    text-transform: uppercase;
    color: #bbbbbd;
}

.jcolor-sliders-input-subcontainer > input {
    border: 0px;
    padding: 1px;
}

.jcolor-sliders-input-container input::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: #000;
    border: 2px solid #fff;
    cursor: pointer;
}

.jcolor-sliders-input-container input::-moz-range-thumb {
    -webkit-appearance: none;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: #000;
    border: 2px solid #fff;
    cursor: pointer;
}

.jcolor-sliders-final-color {
    padding: 6px;
    user-select: all;
    margin-top: 10px;
    text-align: center;
}

.jcolor-sliders-final-color > div:nth-child(2) {
    width: 71px;
    text-transform: uppercase;
}

.jcolor .jtabs .jtabs-headers-container .jtabs-controls {
    display: none !important;
}

.jcolor .jtabs .jtabs-headers-container {
    display: flex !important;
    justify-content: center;
    padding: 4px;
}

.jcolor .jtabs-headers > div:not(.jtabs-border) {
    padding: 2px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    font-size: 0.8em;
}
.jcontextmenu {
    position:fixed;
    z-index:10000;
    background:#fff;
    color: #555;
    font-size: 11px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -webkit-box-shadow: 2px 2px 2px 0px rgba(143, 144, 145, 1);
    -moz-box-shadow: 2px 2px 2px 0px rgba(143, 144, 145, 1);
    box-shadow: 2px 2px 2px 0px rgba(143, 144, 145, 1);
    border: 1px solid #C6C6C6;
    padding: 0px;
    padding-top:4px;
    padding-bottom:4px;
    margin:0px;
    outline:none;
    display:none;
}

.jcontextmenu.jcontextmenu-focus {
    display:inline-block;
}

.jcontextmenu > div {
    box-sizing: border-box;
    display: flex;
    padding: 8px 8px 8px 32px;
    width: 250px;
    position: relative;
    cursor: default;
    font-size: 11px;
    font-family:sans-serif;
    text-align: left;
    -webkit-box-align: center;
    align-items: center;
}

.jcontextmenu > div::before {
    content: attr(data-icon);
    font-family: 'Material Icons' !important;
    font-size: 15px;
    position: absolute;
    left: 9px;
    line-height: 24px;
}

.jcontextmenu.symbols > div::before {
    font-family: 'Material Symbols Outlined' !important;
}

.jcontextmenu > div.header {
    display: none;
}

.jcontextmenu > div a {
    color: #555;
    text-decoration: none;
    flex: 1;
    cursor: pointer;
}

.jcontextmenu > div span {
    margin-right: 10px;
    font-size: 0.9em;
}

.jcontextmenu .jcontextmenu-disabled a {
    color: #ccc;
}

.jcontextmenu .jcontextmenu-disabled::before {
    color: #ccc;
}

.jcontextmenu > div:hover {
    background: #ebebeb;
}

.jcontextmenu hr {
    border: 1px solid #e9e9e9;
    border-bottom: 0;
    margin-top:5px;
    margin-bottom:5px;
}

.jcontextmenu > hr:hover {
    background: transparent;
}

.jcontextmenu .jcontextmenu {
    top: 4px;
    left: 99%;
    opacity: 0;
    position: absolute;
}

.jcontextmenu > div:hover > .jcontextmenu {
    display: block;
    opacity: 1;
    -webkit-transform: translate(0, 0) scale(1);
    transform: translate(0, 0) scale(1);
    pointer-events: auto;
}

@media only screen and (max-width: 420px) {
    .jcontextmenu {
        top: initial !important;
        left: 0px !important;
        bottom: 0px !important;
        width: 100vw;
        height: 260px;
        overflow: scroll;
        animation: jslide-bottom-in 0.4s forwards;
        padding-top: 0px;
    }
    .jcontextmenu div {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #ccc;
        padding: 15px;
    }
    .jcontextmenu > div.header {
        background-color: lightgray;
        padding: 5px;
        top: 0px;
        position: sticky;
        z-index: 2;
    }
    .jcontextmenu > div.header > a.title {
        text-align: left;
    }

    .jcontextmenu > div.header > a.close {
        text-align: right;
    }
    .jcontextmenu a {
        font-size: 1.4em;
        text-transform: uppercase;
    }
    .jcontextmenu span {
        display: none;
    }
    .jcontextmenu span {
        display: none;
    }
    .jcontextmenu hr {
        display: none;
    }
}

.jdropdown {
    cursor:pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    box-sizing: border-box;
    background:#fff;
    -webkit-tap-highlight-color: transparent;
    display: inline-block;
}

.jdropdown-backdrop {
    position:fixed;
    top:0px;
    left:0px;
    min-width:100%;
    min-height:100%;
    background-color:rgba(0,0,0,0.5);
    border:0px;
    padding:0px;
    z-index:8000;
    display:none;
}

.jdropdown[disabled] {
    opacity: 0.5;
    pointer-events: none;
}

.jdropdown-focus {
    position:relative;
}

.jdropdown-focus .jdropdown-container {
    transform: translate3d(0,0,0);
}

.jdropdown-default.jdropdown-focus .jdropdown-header {
    outline:auto 5px -webkit-focus-ring-color;
}

.jdropdown-default.jdropdown-focus .jdropdown-header.jdropdown-add {
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27gray%27 width=%2724px%27 height=%2724px%27%3E%3Cpath d=%27M0 0h24v24H0z%27 fill=%27none%27/%3E%3Cpath d=%27M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-2 10h-4v4h-2v-4H7v-2h4V7h2v4h4v2z%27/%3E%3C/svg%3E");
}

.jdropdown-container-header {
    padding:0px;
    margin:0px;
    position:relative;
    box-sizing: border-box;
}

.jdropdown-header {
    width:100%;
    appearance: none;
    background-repeat: no-repeat;
    background-position:top 50% right 5px;
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2724%27 height=%2724%27 viewBox=%270 0 24 24%27%3E%3Cpath fill=%27none%27 d=%27M0 0h24v24H0V0z%27/%3E%3Cpath d=%27M7 10l5 5 5-5H7z%27 fill=%27gray%27/%3E%3C/svg%3E");
    text-overflow: ellipsis;
    cursor:pointer;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right:30px !important;
}

.jdropdown-insert-button {
    font-size: 1.4em;
    text-transform: uppercase;
    position:absolute;
    right: 30px;
    top: 4px;
    display:none;
}

.jdropdown-container {
    min-width: inherit;
    transform: translate3d(-10000px,0,0);
    position:absolute;
    z-index:9001;
}

.jdropdown-close {
    display:none;
    font-size:1em;
    color: var(--jactive-color);
    text-transform:uppercase;
    text-align:right;
    padding:12px;
    font-weight:bold;
}

.jdropdown-content {
    min-width:inherit;
    margin:0px;
    box-sizing:border-box;
}

.jdropdown-content:empty {
}

.jdropdown-item {
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;
    overflow-x: hidden;
    color: #000;
    display: flex;
    align-items: center;
}

.jdropdown-description {
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 1.5em;
}

.jdropdown-image {
    margin-right:10px;
    width: 32px;
    height: 32px;
    border-radius:20px;
}

.jdropdown-image-small {
    width:24px;
    height:24px;
}

.jdropdown-icon {
    margin-right:10px;
    font-size: 30px;
    margin-left: -5px;
}

.jdropdown-icon-small {
    font-size: 24px;
    margin-left: 0px;
}

.jdropdown-title {
    font-size: 0.7em;
    text-overflow: ellipsis;
    overflow-x: hidden;
    display: block;
}

/** Default visual **/

.jdropdown-default .jdropdown-header {
    border:1px solid #ccc;
    padding:5px;
    padding-left:10px;
    padding-right:16px;
}

.jdropdown-default .jdropdown-container {
    background-color:#fff;
}

.jdropdown-default.jdropdown-focus.jdropdown-insert .jdropdown-header {
    padding-right:50px;
}

.jdropdown-default.jdropdown-focus.jdropdown-insert .jdropdown-insert-button {
    display:block;
}

.jdropdown-default .jdropdown-content
{
    min-width:inherit;
    border:1px solid #8fb1e3;
    margin:0px;
    background-color:#fff;
    box-sizing:border-box;
    min-height:10px;
    max-height:215px;
    overflow-y:auto;
}

.jdropdown-default .jdropdown-item
{
    padding:4px;
    padding-left:8px;
    padding-right:40px;
}

.jdropdown-default .jdropdown-item:hover
{
    background-color:#1f93ff;
    color:#fff;
}

.jdropdown-default .jdropdown-cursor
{
    background-color:#eee;
}

.jdropdown-default .jdropdown-selected
{
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIiAvPjxwYXRoIGQ9Ik05IDE2LjE3TDQuODMgMTJsLTEuNDIgMS40MUw5IDE5IDIxIDdsLTEuNDEtMS40MXoiIGZpbGw9IndoaXRlIiAvPjwvc3ZnPgo=);
    background-repeat:no-repeat;
    background-position:top 50% right 5px;
    background-color:#1f93ff;
    color:#fff;
}

.jdropdown-default .jdropdown-group {
    margin-top:5px;
}

.jdropdown-default .jdropdown-group .jdropdown-item {
    padding-left:16px;
}

.jdropdown-default .jdropdown-group-name {
    padding-left: 8px;
    font-weight: bold;
    text-align: left;
}

.jdropdown-default .jdropdown-reset_ {
    content:'x';
    position:absolute;
    top:0;
    right:0;
    margin:5px;
    margin-right:10px;
    font-size:12px;
    width:12px;
    cursor:pointer;
    text-shadow: 0px 0px 5px #fff;
    display:none;
    line-height: 1.8em;
}

.jdropdown-default.jdropdown-focus .jdropdown-reset_ {
    display:block;
}

/** Default render for mobile **/

.jdropdown-picker.jdropdown-focus .jdropdown-backdrop {
    display:block;
}

.jdropdown-picker .jdropdown-header {
    outline: none;
}

.jdropdown-picker .jdropdown-container
{
    position:fixed;
    bottom:0px;
    left:0px;
    border-bottom:1px solid #e6e6e8;
    width:100%;
    background-color:#fff;
    box-sizing: border-box;
}

.jdropdown-picker .jdropdown-close
{
    -webkit-box-shadow: 0px -1px 5px 0px rgba(0,0,0,0.39);
    -moz-box-shadow: 0px -1px 5px 0px rgba(0,0,0,0.39);
    box-shadow: 0px -1px 5px 0px rgba(0,0,0,0.39);
    background-color:#fff;
    display:block;
}

.jdropdown-picker .jdropdown-content
{
    overflow-y:scroll;
    height:280px;
    background-color:#fafafa;
    border-top:1px solid #e6e6e8;
}

.jdropdown-picker .jdropdown-group-name
{
    font-size: 1em;
    text-transform: uppercase;
    padding-top:10px;
    padding-bottom:10px;
    display: block;
    border-bottom: 1px solid #e6e6e8;
    padding-left:20px;
    padding-right:20px;
    text-align:center;
    font-weight:bold;
}

.jdropdown-picker .jdropdown-item
{
    font-size: 1em;
    text-transform: uppercase;
    padding-top:10px;
    padding-bottom:10px;
    border-bottom: 1px solid #e6e6e8;
    padding-left:20px;
    padding-right:20px;
}

.jdropdown-picker .jdropdown-selected
{
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIiAvPjxwYXRoIGQ9Ik05IDE2LjE3TDQuODMgMTJsLTEuNDIgMS40MUw5IDE5IDIxIDdsLTEuNDEtMS40MXoiIGZpbGw9IndoaXRlIiAvPjwvc3ZnPgo=);
    background-repeat:no-repeat;
    background-position:top 50% right 15px;
    background-color:#1f93ff;
    color:#fff;
}

.jdropdown-picker .jdropdown-cursor
{
    background-color:#1f93ff;
    color:#fff;
}

/** Default render for mobile searchbar **/

.jdropdown-searchbar.jdropdown-focus
{
    position:fixed;
    top:0px !important;
    left:0px !important;
    width:100% !important;
    height:100% !important;
    background-color:#fafafa;
    padding:0px;
    z-index:9001;
    overflow-y:scroll;
    will-change: scroll-position;
    -webkit-overflow-scrolling: touch;
}

.jdropdown-searchbar.jdropdown-focus .jdropdown-container-header
{
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 9002;
    padding: 6px;
    background-color:#fff;
    box-shadow: 0 1px 2px rgba(0,0,0,.1);
    width: 100%;
    height: 40px;
}

.jdropdown-searchbar.jdropdown-focus .jdropdown-header
{
    border: 0px !important;
    background-position-x: 0% !important;
    background-position-y: 40% !important;
    background-repeat: no-repeat;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTUuNSAxNGgtLjc5bC0uMjgtLjI3QzE1LjQxIDEyLjU5IDE2IDExLjExIDE2IDkuNSAxNiA1LjkxIDEzLjA5IDMgOS41IDNTMyA1LjkxIDMgOS41IDUuOTEgMTYgOS41IDE2YzEuNjEgMCAzLjA5LS41OSA0LjIzLTEuNTdsLjI3LjI4di43OWw1IDQuOTlMMjAuNDkgMTlsLTQuOTktNXptLTYgMEM3LjAxIDE0IDUgMTEuOTkgNSA5LjVTNy4wMSA1IDkuNSA1IDE0IDcuMDEgMTQgOS41IDExLjk5IDE0IDkuNSAxNHoiIGZpbGw9IiNlNmU2ZTgiLz48cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+PC9zdmc+);
    padding-left: 30px !important;
    padding-right: 60px !important;
}

.jdropdown-searchbar.jdropdown-focus .jdropdown-close
{
    display:block;
}

.jdropdown-searchbar .jdropdown-header {
    outline: none;
}

.jdropdown-searchbar .jdropdown-container
{
    margin-top: 40px;
    width:100%;
}

.jdropdown-searchbar .jdropdown-close
{
    position:fixed;
    top:0px;
    right:0px;
}

.jdropdown-searchbar .jdropdown-content
{
    margin-top:10px;
}

.jdropdown-searchbar .jdropdown-group
{
    margin-top:10px;
    margin-bottom:15px;
    background-color:#fff;
}

.jdropdown-searchbar .jdropdown-group-name
{
    border-top: 1px solid #e6e6e8;
    border-bottom: 1px solid #e6e6e8;
    padding:10px;
    padding-left:12px;
    font-weight:bold;
}

.jdropdown-searchbar .jdropdown-group-arrow
{
    float:right;
    width:24px;
    height:24px;
    background-repeat:no-repeat;
}

.jdropdown-searchbar .jdropdown-group-arrow-down
{
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNNy40MSA4LjU5TDEyIDEzLjE3bDQuNTktNC41OEwxOCAxMGwtNiA2LTYtNiAxLjQxLTEuNDF6Ii8+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyNHYyNEgwVjB6Ii8+PC9zdmc+);
}

.jdropdown-searchbar .jdropdown-group-arrow-up
{
    background-image: url(data:image/svg+xml;base64,CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTcuNDEgMTUuNDFMMTIgMTAuODNsNC41OSA0LjU4TDE4IDE0bC02LTYtNiA2eiIvPjxwYXRoIGQ9Ik0wIDBoMjR2MjRIMHoiIGZpbGw9Im5vbmUiLz48L3N2Zz4=);
}

.jdropdown-searchbar .jdropdown-item
{
    padding-top:10px;
    padding-bottom:10px;
    border-bottom: 1px solid #e6e6e8;
    padding-left:15px;
    padding-right:40px;
    background-color:#fff;
    font-size:0.9em;
}

.jdropdown-searchbar .jdropdown-description {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: calc(100% - 20px);
}

.jdropdown-searchbar .jdropdown-content > .jdropdown-item:first-child
{
    border-top: 1px solid #e6e6e8;
}

.jdropdown-searchbar .jdropdown-selected
{
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+PHBhdGggZD0iTTkgMTYuMTdMNC44MyAxMmwtMS40MiAxLjQxTDkgMTkgMjEgN2wtMS40MS0xLjQxeiIgZmlsbD0iIzAwN2FmZiIvPjwvc3ZnPg==);
    background-repeat:no-repeat;
    background-position:top 50% right 15px;
}

/** List render **/

.jdropdown-list
{
}

.jdropdown-list .jdropdown-container
{
    display:block;
}

.jdropdown-list .jdropdown-header
{
    display:none;
}

.jdropdown-list .jdropdown-group
{
    background-color:#fff;
}

.jdropdown-list .jdropdown-group-name
{
    border-bottom: 1px solid #e6e6e8;
    padding-top:10px;
    padding-bottom:10px;
    font-weight:bold;
}

.jdropdown-list .jdropdown-item
{
    padding-top:10px;
    padding-bottom:10px;
    border-bottom: 1px solid #e6e6e8;
    padding-left:10px;
    padding-right:40px;
    background-color:#fff;
}

.jdropdown-list .jdropdown-selected
{
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+PHBhdGggZD0iTTkgMTYuMTdMNC44MyAxMmwtMS40MiAxLjQxTDkgMTkgMjEgN2wtMS40MS0xLjQxeiIgZmlsbD0iIzAwN2FmZiIvPjwvc3ZnPg==);
    background-repeat:no-repeat;
    background-position:top 50% right 10px;
}

@media only screen and (max-width : 800px)
{
    .jdropdown-list {
        width:100% !important;
        border:0px;
        padding:0px;
    }

    .jdropdown-list .jdropdown-container {
        min-width:100%;
    }

    .jdropdown-searchbar.jdropdown-focus .jdropdown-description {
        text-transform: uppercase;
    }
}

.app .jdropdown-item {
    text-transform:uppercase;
}

.jdropdown-create-container {
    margin: 10px;
    border: 1px solid #ccc;
    border-radius: 2px;
    padding: 6px;
}

.jdropdown-color {
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 12px;
    width: 12px;
    height: 12px;
    margin-right: 6px;
}

.jdropdown-item[data-disabled] {
    opacity: 0.5;
    pointer-events: none;
}
.jeditor-container {
    border:1px solid #ccc;
    box-sizing: border-box;
}

.jeditor-container.with-margin {
    background-color: #f2f2f2;
    max-width: 1200px;
}

.jeditor-dragging {
     border:1px dashed #000;
}

.jeditor {
    outline:none;
    word-break: break-word;
}

.jeditor-container.with-margin .jeditor {
    background-color: #fff;
    margin: 80px;
    min-height: 800px;
    padding: 80px;
    max-width: 800px;
}

.jeditor[data-placeholder]:empty:before {
    content: attr(data-placeholder);
    color: lightgray;
}

/** Snippet **/

.jsnippet {
    margin-top:15px;
    cursor:pointer;
    border: 1px solid #ccc;
    position:relative;
}

.jsnippet:focus {
    outline: none;
}

.jsnippet img {
    width:100%;
}

.jsnippet .jsnippet-title {
    padding:15px;
    font-size:1.4em;
}

.jsnippet .jsnippet-description {
    padding-left:15px;
    padding-right:15px;
    font-size:1em;
}

.jsnippet .jsnippet-host {
    padding:15px;
    text-transform:uppercase;
    font-size:0.8em;
    color:#777;
    text-align:right;
}

.jsnippet .jsnippet-url {
    display:none;
}

.jeditor .jsnippet:after {
    content: 'close';
    font-family: 'Material icons';
    font-size: 24px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    cursor: pointer;
    text-shadow: 0px 0px 2px #fff;
    position: absolute;
    top: 12px;
    right: 12px;
}

.jsnippet * {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;

    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
}

.jeditor img {
    border:2px solid transparent;
    box-sizing: border-box;
}

.jeditor img.resizing {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;

    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
}

.jeditor img:focus {
    border: 2px solid #0096FD;
    outline: #0096FD;
}

.jeditor .pdf {
    background-image: url("data:image/svg+xml,%3Csvg version=%271.1%27 id=%27Layer_1%27 xmlns=%27http://www.w3.org/2000/svg%27 xmlns:xlink=%27http://www.w3.org/1999/xlink%27 x=%270px%27 y=%270px%27 viewBox=%270 0 512 512%27 style=%27enable-background:new 0 0 512 512;%27 xml:space=%27preserve%27%3E%3Cpath style=%27fill:%23C30B15;%27 d=%27M511.344,274.266C511.77,268.231,512,262.143,512,256C512,114.615,397.385,0,256,0S0,114.615,0,256 c0,117.769,79.53,216.949,187.809,246.801L511.344,274.266z%27/%3E%3Cpath style=%27fill:%2385080E;%27 d=%27M511.344,274.266L314.991,77.913L119.096,434.087l68.714,68.714C209.522,508.787,232.385,512,256,512 C391.243,512,501.976,407.125,511.344,274.266z%27/%3E%3Cpolygon style=%27fill:%23FFFFFF;%27 points=%27278.328,333.913 255.711,77.913 119.096,77.913 119.096,311.652 %27/%3E%3Cpolygon style=%27fill:%23E8E6E6;%27 points=%27392.904,311.652 392.904,155.826 337.252,133.565 314.991,77.913 255.711,77.913 256.067,333.913 %27/%3E%3Cpolygon style=%27fill:%23FFFFFF;%27 points=%27314.991,155.826 314.991,77.913 392.904,155.826 %27/%3E%3Crect x=%27119.096%27 y=%27311.652%27 style=%27fill:%23FC0F1A;%27 width=%27273.809%27 height=%27122.435%27/%3E%3Cg%3E%3Cpath style=%27fill:%23FFFFFF;%27 d=%27M204.871,346.387c13.547,0,21.341,6.659,21.341,18.465c0,12.412-7.795,19.601-21.341,19.601h-9.611 v14.909h-13.471v-52.975L204.871,346.387L204.871,346.387z M195.26,373.858h8.93c5.904,0,9.308-2.952,9.308-8.552 c0-5.525-3.406-8.324-9.308-8.324h-8.93V373.858z%27/%3E%3Cpath style=%27fill:%23FFFFFF;%27 d=%27M257.928,346.387c16.649,0,28.152,10.746,28.152,26.487c0,15.666-11.655,26.488-28.683,26.488 h-22.25v-52.975H257.928z M248.619,388.615h9.611c8.249,0,14.151-6.357,14.151-15.665c0-9.384-6.205-15.817-14.757-15.817h-9.006 V388.615z%27/%3E%3Cpath style=%27fill:%23FFFFFF;%27 d=%27M308.563,356.982v12.26h23.763v10.596h-23.763v19.525h-13.471v-52.975h39.277v10.595h-25.806 V356.982z%27/%3E%3C/g%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: cover;
    width:60px;
    height:60px;
}

.jeditor-toolbar {
    width: fit-content;
    max-width: 100%;
    box-sizing: border-box;
    margin: 10px;
}

.toolbar-on-top .jeditor-toolbar {
    width: initial;
    margin: 0px;
    box-shadow: 1px 1px 2px rgb(0 0 0 / 10%);
    display: block;
}

.toolbar-on-top .jeditor {
    padding: 15px;
}

.toolbar-on-top .jtoolbar .material-icons {
    font-size: 24px;
    transform: initial;
    margin: 4px;
}

.toolbar-on-top .jtoolbar .jpicker-header {
    font-size: 1em;
    margin-top: 4px;
    margin-bottom: 4px;
}

.jeditor table {
    border-collapse: collapse;
}

.jeditor table td {
    border: 1px solid #bbb;
    height: 2em;
}

.jeditor table td:focus {
    border: 1px solid blue;
}

.jeditor .line-break {
    border-top: 1px dashed #ccc;
    display: flex;
    justify-content: center;
    pointer-events: none;
}

.jeditor .line-break:before {
    content: 'New page';
    background-color: #fff;
    color: #ccc;
    margin: -1em;
    padding: 6px;
    position: absolute;
}
.jfloating {
    position:fixed;
    bottom:0px;
    right:0px;
    margin-right:5px;

    -webkit-box-shadow: 0 2px 10px rgba(0,0,0,.2);
    -moz-box-shadow: 0 2px 10px rgba(0,0,0,.2);
    border:1px solid #ccc;
    background-color:#fff;
    box-sizing: border-box;
    padding-top:50px !important;
    z-index:9002;
    border-radius: 8px;
}

.jfloating.jfloating-big {
    width: 510px !important;
    height: 472px !important;
}

.jfloating.jfloating-small {
    width: 300px !important;
    height: 320px !important;
}

.jfloating.jfloating-large {
    width: 600px !important;
    height: 600px !important;
}

.jfloating:before {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    content:attr(title);
    padding:15px;
    box-sizing: border-box;
    font-size:1.2em;
    box-shadow: 1px 1px 3px rgba(0,0,0,.2);
    background-color: #fff;
    border-radius: 8px 8px 0px 0px;
    background-color: #404040;
    font-size: .93rem;
    font-weight: 600;
    color: white;
    letter-spacing: .5px;
}

.jfloating:after {
    content:'';
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2724%27 height=%2724%27 viewBox=%270 0 24 24%27%3E%3Cpath fill=%27%23FFF%27 d=%27M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z%27/%3E%3Cpath d=%27M0 0h24v24H0z%27 fill=%27none%27/%3E%3C/svg%3E");
    position:absolute;
    top:0;
    right:0;
    margin:14px;
    font-size:24px;
    width:24px;
    height:24px;
    cursor:pointer;
    text-shadow: 0px 0px 5px #fff;
}

.jfloating_content {
    padding:20px;
    overflow-y:auto;
    max-height:100%;
    box-sizing: border-box;
    height: -webkit-fill-available;
}

.jfloating.jfloating-minimized {
    height: 50px !important;
}

.jfloating.jfloating-minimized .jfloating_content {
    display: none;
}

.jmodal {
    position:fixed;
    top:50%;
    left:50%;
    width:60%;
    height:60%;
    -webkit-box-shadow: 0 2px 12px rgba(0,0,0,.2);
    -moz-box-shadow: 0 2px 12px rgba(0,0,0,.2);
    border:1px solid #ccc;
    background-color:#fff;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    z-index:9002;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
}

.jmodal_title {
    padding: 20px;
    height: 70px;
    box-sizing: border-box;
    font-size: 1.4em;
    background-color: #fff;
    border-radius: 8px 8px 0px 0px;
    pointer-events: none;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    border-bottom: 1px solid #eee;
}

.jmodal_title > div {
    font-size: 1.4em;
}

.jmodal_title[data-icon]:before {
    content: attr(data-icon);
    font-family: 'Material Icons' !important;
    width: 24px;
    height: 24px;
    font-size: 24px;
    margin-right: 10px;
    line-height: 24px;
}

.jmodal_content {
    padding: 20px;
    overflow-y: auto;
    height: 100%;
    box-sizing: border-box;
    scrollbar-width: thin;
    scrollbar-color: #333 transparent;
}

.jmodal_title:empty {
    display: none;
}

.jmodal_title:empty + .jmodal_content {
    height: 100%;
}

.jmodal_content::-webkit-scrollbar {
    height: 12px;
}

.jmodal_content::-webkit-scrollbar {
    width: 12px;
}

.jmodal_content::-webkit-scrollbar-track {
    border: 1px solid #fff;
    background: #eee;
}

.jmodal_content::-webkit-scrollbar-thumb {
    border: 1px solid #fff;
    background: #888;
}

.jmodal:after {
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2724%27 height=%2724%27 viewBox=%270 0 24 24%27%3E%3Cpath d=%27M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z%27/%3E%3Cpath d=%27M0 0h24v24H0z%27 fill=%27none%27/%3E%3C/svg%3E");
    position: absolute;
    top: 0;
    right: 0;
    margin: 25px;
    font-size: 24px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    text-shadow: 0px 0px 5px #fff;
}

.jmodal_fullscreen {
    width: 100% !important;
    height: 100% !important;
    top: 0px;
    left: 0px;
    transform: none;
    border: 0px;
    border-radius: 0px;
}

.jmodal_backdrop {
    position: fixed;
    top: 0px;
    left: 0px;
    min-width: 100%;
    min-height: 100%;
    background-color: rgba(0,0,0,0.2);
    border: 0px;
    padding: 0px;
    z-index: 8000;
    display: none;

  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.jmodal_content .jcalendar .jcalendar-content,
.jmodal_content .jdropdown-container {
    position: fixed;
}

.jnotification {
    position: fixed;
    z-index: 10000;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px;
    bottom: 0px;
}

.jnotification-container {
    -webkit-box-shadow: 0px 2px 15px -5px rgba(0, 0, 0, 0.7);
    box-shadow: 0px 2px 15px -5px rgba(0, 0, 0, 0.7);
    padding: 12px;
    border-radius: 8px;

    background-color: #000;
    background: rgba(92,92,92,1);
    background: linear-gradient(0deg, rgba(92,92,92,1) 0%, rgba(77,77,77,1) 100%);
    color: #fff;
    width: 320px;
    margin: 30px;
    padding: 20px;
}

.jnotification-close {
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2720%27 height=%2720%27 viewBox=%270 0 24 24%27 fill=%27white%27%3E%3Cpath d=%27M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z%27/%3E%3Cpath d=%27M0 0h24v24H0z%27 fill=%27none%27/%3E%3C/svg%3E");
    font-size: 20px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.jnotification-title {
    font-weight: bold;
}

.jnotification-header {
    display: flex;
    padding-bottom: 5px;
}

.jnotification-header:empty {
    display: none;
}

.jnotification-image {
    margin-right: 5px;
}

.jnotification-image:empty {
    display: none;
}

.jnotification-image img {
    width: 24px;
}

.jnotification-name {
    text-transform: uppercase;
    font-size: 0.9em;
    flex: 1;
    letter-spacing: 0.1em;
}

.jnotification-error .jnotification-container {
    background: rgb(182,38,6);
    background: linear-gradient(0deg, rgba(170,41,13,1) 0%, rgba(149,11,11,1) 100%);
}

@media (max-width: 800px) {
    .jnotification {
        top: calc(0px + var(--jsafe-area-top));
        width: 100%;
    }
    .jnotification-container {
        background: rgba(255,255,255,0.95);
        border: 1px solid #eee;
        color: #444;
        margin: 0px;
        width: initial;
    }
    .jnotification-error .jnotification-container {
        background: rgba(255,255,255,0.95);
        color: #790909;
    }
    .jnotification-close {
        background-image: url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2720%27 height=%2720%27 viewBox=%270 0 24 24%27 fill=%27black%27%3E%3Cpath d=%27M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z%27/%3E%3Cpath d=%27M0 0h24v24H0z%27 fill=%27none%27/%3E%3C/svg%3E");
    }
}

.jnotification-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
.jpicker {
    cursor: pointer;
    white-space: nowrap;
    display: inline-flex;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: none;
    position: relative;
    min-height: 26px;
}

.jpicker-header {
    background-repeat: no-repeat;
    background-position: top 50% right 5px;
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2724%27 height=%2724%27 viewBox=%270 0 24 24%27%3E%3Cpath fill=%27none%27 d=%27M0 0h24v24H0V0z%27/%3E%3Cpath d=%27M7 10l5 5 5-5H7z%27 fill=%27gray%27/%3E%3C/svg%3E");
    text-overflow: ellipsis;
    cursor: pointer;
    box-sizing: border-box;
    text-align: left;
    outline: none;
    line-height: 24px;
    padding: 2px 35px 2px 12px;
    border-radius: 4px;
}

.jpicker-header:hover {
    background-color: #f2f2f2;
}

.jpicker-content {
    position: absolute;
    top: 0;
    display: none;
    box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.2);
    border-radius: 4px;
    background-color: #fff;
    padding: 4px;
    z-index: 50;
    text-align: left;
    max-height: 250px;
    scrollbar-width: thin;
    scrollbar-color: #333 transparent;
}

.jpicker-content::-webkit-scrollbar {
    width: 8px;
}

.jpicker-content::-webkit-scrollbar-track {
    background: #eee;
}
 
.jpicker-content::-webkit-scrollbar-thumb {
    background: #888;
}
 
.jpicker-content > div {
    padding: 6px;
    padding-left: 15px;
    padding-right: 15px;
}

.jpicker-focus > .jpicker-content {
    display: block;
}

.jpicker-content > div:hover {
    background-color:#efefef;
}

.jpicker-content > div:empty {
    opacity: 0;
}

.jpicker-header > i, .jpicker-header > div {
    display: block;
}

.jpicker-focus > .jpicker-content.jpicker-columns {
    display: flex !important ;
    justify-content: center;
    flex-wrap: wrap;
}

.jpicker-focus .jpicker-content.jpicker-grid {
    display: inline-grid;
}



.jprogressbar
{
    cursor:pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    box-sizing: border-box;
    background:#fff;
    -webkit-tap-highlight-color: transparent;
    display: inline-block;
    box-sizing: border-box;
    cursor:pointer;
    border:1px solid #ccc;
    position:relative;
}

.jprogressbar::before {
    content:attr(data-value);
    position:absolute;
    margin:5px;
    margin-left:10px;
}

.jprogressbar-header::placeholder
{
    color:#000;
}

.jprogressbar:focus {
    outline: auto 5px -webkit-focus-ring-color;
}

.jprogressbar > div {
    background-color: #eee;
    background-color: red;
    box-sizing: border-box;
    height:31px;
}
.jrating {
    display:flex;
}
.jrating > div {
    width:24px;
    height:24px;
    line-height:24px;
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2724%27 height=%2724%27 viewBox=%270 0 24 24%27%3E%3Cpath d=%27M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z%27 fill=%27gray%27/%3E%3Cpath d=%27M0 0h24v24H0z%27 fill=%27none%27/%3E%3C/svg%3E");
}

.jrating .jrating-over {
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2724%27 height=%2724%27 viewBox=%270 0 24 24%27 fill=%27black%27%3E%3Cpath d=%27M0 0h24v24H0z%27 fill=%27none%27/%3E%3Cpath d=%27M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z%27/%3E%3Cpath d=%27M0 0h24v24H0z%27 fill=%27none%27/%3E%3C/svg%3E");
    opacity: 0.7;
}

.jrating .jrating-selected {
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2724%27 height=%2724%27 viewBox=%270 0 24 24%27 fill=%27red%27%3E%3Cpath d=%27M0 0h24v24H0z%27 fill=%27none%27/%3E%3Cpath d=%27M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z%27/%3E%3Cpath d=%27M0 0h24v24H0z%27 fill=%27none%27/%3E%3C/svg%3E");
}

.jsearch {
    position: relative;
    display: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.jsearch_container {
    position: absolute;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.302), 0 2px 6px 2px rgba(60,64,67,0.149);
    border: none;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    width: 280px;
    padding: 8px 0;
    z-index: 1;

    -webkit-box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    -webkit-transition: opacity .218s;
    transition: opacity .218s;
    background: #fff;
    border: 1px solid rgba(0,0,0,.2);
    cursor: pointer;
    margin: 0;
    min-width: 300px;
    outline: none;
    width: auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.jsearch_container:empty:after {
    content: attr(data-placeholder);
}

.jsearch_container > div {
    color: #333;
    cursor: pointer;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    padding: 5px 10px;
    user-select: none;
    -webkit-align-items: center;
    align-items: center;
    
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.jsearch_container > div:hover {
    background-color: #e8eaed;
}

.jsearch_container > div > img {
    width: 32px;
    height: 32px;
    user-select: none;
    border-radius: 16px;
    margin-right: 2px;
}

.jsearch_container > div > div {
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 2px;
    max-width: 300px;
    white-space: nowrap;
    user-select: none;
}

.jsearch_container .selected {
    background-color: #e8eaed;
}
.jslider {
    outline: none;
}

.jslider-focus {
    width: 100% !important;
    height: 100% !important;
}

.jslider-focus img {
    display: none;
}

.jslider img {
    width: 100px;
}

.jslider-left::before {
    position: fixed;
    left: 15px;
    top: 50%;
    content:'arrow_back_ios';
    color: #fff;
    width: 30px;
    height: 30px;
    font-family: 'Material Icons';
    font-size: 30px;
    /* before it was 0px 0px 0px #000 */
    text-shadow: 0px 0px 6px rgb(56,56,56);
    text-align: center;
    cursor: pointer;
}

.jslider-right::after {
    position: fixed;
    right: 15px;
    top: 50%;
    content: 'arrow_forward_ios';
    color: #fff;
    width: 30px;
    height: 30px;
    font-family: 'Material Icons';
    font-size: 30px;
    /* before it was 0px 0px 0px #000 */
    text-shadow: 0px 0px 6px rgb(56,56,56);
    text-align: center;
    cursor: pointer;
}

.jslider-close {
    width:24px;
    height:24px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2724%27 height=%2724%27 viewBox=%270 0 24 24%27 fill=%27white%27%3E%3Cpath d=%27M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z%27/%3E%3Cpath d=%27M0 0h24v24H0z%27 fill=%27none%27/%3E%3C/svg%3E");
    position:fixed;
    top:15px;
    right:15px;
    cursor:pointer;
    z-index:3000;

    display: block !important;
}

.jslider-counter {
    height:24px;
    background-color: transparent;
    position:fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 15px;
    cursor:pointer;
    z-index:3000;

    display: flex;
    display: -webkit-flex;
    -webkit-justify-content: center;
    -webkit-align-items: center;   
    -webkit-flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.jslider-caption {
    position: fixed;
    max-width: 90vw;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    top:15px;
    left: 15px;
    z-index:3000;
    color: #FFF;
    font-size: 1rem;

    display: block !important;
}

.jslider-counter div {
    width: 10px;
    height: 10px;
    background: #fff;
    border-radius: 50%;
    margin: 0px 5px;

    display: block !important;
}

.jslider-counter .jslider-counter-focus {
    background-color: cornflowerblue;
    pointer-events: none;
}

.jslider-focus {
    position:fixed;
    left:0;
    top:0;
    width: 100%;
    min-height:100%;
    max-height:100%;
    z-index:2000;
    margin:0px;
    box-sizing:border-box;

    background-color:rgba(0,0,0,0.8);
    -webkit-transition-duration: .05s;
    transition-duration: .05s;
    display: flex;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;

    align-items: center;
}

.jslider-focus img {
    width: 50vw;
    height: auto;
    box-sizing: border-box;
    margin:0 auto;
    vertical-align:middle;
    display:none;
}

.jslider-focus img.jslider-vertical {
    width: auto;
    /* before it was 50vh */
    height: 80vh;
}

@media only screen and (max-width: 576px) {
    .jslider-focus img.jslider-vertical {
        width: 99vw !important;
        height: auto !important;
    }

    .jslider-focus img {
        width: 100vw !important;
        height: auto !important;
    }
}

.jslider-grid {
    display: -ms-grid;
    display: grid;
    grid-gap: 1px;
    position: relative;
}

.jslider-grid[data-number='2'] {
    -ms-grid-columns: 1fr 50%;
    grid-template-columns: 1fr 50%;
}

.jslider-grid[data-number='3'] {
    -ms-grid-columns: 1fr 33%;
    grid-template-columns: 1fr 33%;
}

.jslider-grid[data-number='4'] {
    -ms-grid-columns: 1fr 25%;
    grid-template-columns: 1fr 25%;
}

.jslider-grid img {
    display: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.jslider-grid[data-total]:after {
    content: attr(data-total) "+";
    font-size: 1.5em;
    position:absolute;
    color: #fff;
    right: 15px;
    bottom: 6px;
}

.jslider-grid img:first-child {
    -ms-grid-column: 1;
    -ms-grid-row: 1;
    grid-column: 1;
    grid-row: 1;
    display: block;
}

.jslider-grid[data-number='2'] img:nth-child(2) {
    -ms-grid-column: 2;
    -ms-grid-row: 1;
    grid-column: 2;
    grid-row: 1;
    display: block;
}

.jslider-grid[data-number='3'] img:first-child {
    -ms-grid-column: 1 / 2;
    -ms-grid-row: 1 / 4;
    grid-column: 1 / 2;
    grid-row: 1 / 4;
}

.jslider-grid[data-number='3'] img:nth-child(2) {
    -ms-grid-column: 2;
    -ms-grid-row: 1;
    grid-column: 2;
    grid-row: 1;
    display: block;
}

.jslider-grid[data-number='3'] img:nth-child(3) {
    -ms-grid-column: 2;
    -ms-grid-row: 2;
    grid-column: 2;
    grid-row: 2;
    display: block;
}

.jslider-grid[data-number='4'] img:first-child {
    -ms-grid-column: 1 / 2;
    -ms-grid-row: 1 / 4;
    grid-column: 1 / 2;
    grid-row: 1 / 4;
}

.jslider-grid[data-number='4'] img:nth-child(2) {
    -ms-grid-column: 2;
    -ms-grid-row: 1;
    grid-column: 2;
    grid-row: 1;
    display: block;
}

.jslider-grid[data-number='4'] img:nth-child(3) {
    -ms-grid-column: 2;
    -ms-grid-row: 2;
    grid-column: 2;
    grid-row: 2;
    display: block;
}

.jslider-grid[data-number='4'] img:nth-child(4) {
    -ms-grid-column: 2;
    -ms-grid-row: 3;
    grid-column: 2;
    grid-row: 3;
    display: block;
}

.jtabs {
    max-width: 100vw;
    position: relative;
}

.jtabs .jtabs-headers-container {
    display: flex;
    align-items: center;
}

.jtabs .jtabs-headers {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.jtabs .jtabs-headers > div:not(.jtabs-border) {
    padding: 8px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 1px;
    margin-right: 1px;
    background-color: #f1f1f1;
    cursor: pointer;
    white-space: nowrap;
    text-align: center;
}

.jtabs .jtabs-headers > div.jtabs-selected {
    background-color: #e8e8e8;
    color: #000;
}

.jtabs .jtabs-headers > div > div {
    color: #555;
    width: 100%;
    overflow: hidden;
}

.jtabs .jtabs-headers i {
    display: block;
    margin: auto;
}

.jtabs .jtabs-content {
    box-sizing: border-box;
}

.jtabs .jtabs-content > div {
    display: none;
    box-sizing: border-box;
}

.jtabs .jtabs-content > div.jtabs-selected {
    display: block;
}

.jtabs .jtabs-border {
    position: absolute;
    height: 2px;
    background-color: #888;
    transform-origin: left;
    transition: all .2s cubic-bezier(0.4,0,0.2,1);
    transition-property: color,left,transform;
    display: none;
    pointer-events: none;
}

.jtabs-animation .jtabs-border {
    display: initial;
}

.jtabs .jtabs-controls {
    margin: 3px;
    margin-left: 10px;
    display: flex;
    min-width: 82px;
}

.jtabs .jtabs-controls > div {
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    line-height: 24px;
}

.jtabs .jtabs-prev {
    margin-left: 10px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27gray%27 width=%2718px%27 height=%2718px%27%3E%3Cpath d=%27M0 0h24v24H0z%27 fill=%27none%27/%3E%3Cpath d=%27M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z%27/%3E%3C/svg%3E");
}

.jtabs .jtabs-prev.disabled {
    margin-left: 10px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27lightgray%27 width=%2718px%27 height=%2718px%27%3E%3Cpath d=%27M0 0h24v24H0z%27 fill=%27none%27/%3E%3Cpath d=%27M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z%27/%3E%3C/svg%3E");
}

.jtabs .jtabs-next {
    background-image: url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27gray%27 width=%2718px%27 height=%2718px%27%3E%3Cpath d=%27M0 0h24v24H0z%27 fill=%27none%27/%3E%3Cpath d=%27M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z%27/%3E%3C/svg%3E");
}

.jtabs .jtabs-next.disabled {
    background-image: url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27lightgray%27 width=%2718px%27 height=%2718px%27%3E%3Cpath d=%27M0 0h24v24H0z%27 fill=%27none%27/%3E%3Cpath d=%27M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z%27/%3E%3C/svg%3E");
}

.jtabs .jtabs-add {
    background-image: url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 height=%2724%27 viewBox=%270 0 24 24%27 width=%2724%27%3E%3Cpath d=%27M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-2 10h-4v4h-2v-4H7v-2h4V7h2v4h4v2z%27 fill=%27%23bbbbbb%27/%3E%3Cpath d=%27M0 0h24v24H0z%27 fill=%27none%27/%3E%3C/svg%3E");
}

/** Modern skin **/

.jtabs.jtabs-modern .jtabs-headers > div:not(.jtabs-border) {
    padding: 4px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #fff;
}

.jtabs.jtabs-modern .jtabs-headers > .jtabs-selected  {
    color: #000;
}

.jtabs.jtabs-modern .jtabs-headers > .jtabs-selected .material-icons {
    color: #000;
}

.jtabs.jtabs-modern .jtabs-headers {
    background: #EEEEEF !important;
    padding: 2px;
    border-radius: 4px;
}

.jtabs.jtabs-modern .jtabs-headers .jtabs-border {
    border-color: #EEEEEF !important;
}

.jtabs.jtabs-modern .jtabs-border {
    background-color: rgba(194, 197, 188, 0.884);
}
.jtags {
    display: flex;
    flex-wrap: wrap;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: flex-start;
    -webkit-justify-content: space-between;
    justify-content: flex-start;
    padding: 1px;
    border: 1px solid #ccc;
    position: relative;
} 

.jtags.jtags-empty:not(.jtags-focus)::before {
    position: absolute;
    margin: 3px;
    color: #ccc;
    content: attr(data-placeholder);
    top: 0;
    margin-left: 6px;
}

.jtags > div {
    padding: 3px 22px 3px 10px;
    font-size: 0.9em;
    position: relative;
    border-radius: 1px;
    margin: 2px;
    display: block;
    outline: none;
}

.jtags > div:empty:before {
    content: " ";
    white-space: pre;
}

.jtags > div::after {
    content: 'x';
    position: absolute;
    right: 4px;
    width: 12px;
    height: 12px;
    cursor: pointer;
    font-size: 0.9em;
    display: none;
}

.jtags_label {
    background-color: #e4e4e4 !important;
}

.jtags_label::after {
    display: inline-block !important;
}

.jtags_error::after {
    color: #fff  !important;
}

.jtags_error {
    background-color: #d93025 !important;
    color: #fff;
}

.jtoolbar-container {
    border-radius: 2px;
    box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.2);
    display: inline-flex !important;
}

.jtoolbar {
    cursor: pointer;
    white-space: nowrap;
    display: flex;
    padding:4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 13px;
}

.jtoolbar-disabled {
    pointer-events: none;
    opacity: 0.4;
}

.jtoolbar-mobile {
    display: flex;
    position:fixed;
    bottom: 0;
    margin: 0;
    left: 0;
    width: 100%;
    background: #f7f7f8;
    z-index: 1;
    box-sizing: border-box;
    box-shadow: 0 -1px 2px rgba(0,0,0,.1);
    border-radius: 0px;
}

.jtoolbar > div {
    display: inline-flex;
    align-items: center;
    box-sizing: border-box;
    vertical-align:middle;
    justify-content: space-evenly;
}

.jtoolbar-mobile > div {
    display: flex;
    width: 100%;
}

.jtoolbar .jtoolbar-item {
    text-align: center;
    margin: auto;
    padding: 2px;
    padding-left:4px;
    padding-right:4px;
}

.jtoolbar-mobile .jtoolbar-item {
    position: relative;
    flex:1;
} 

.jtoolbar .jtoolbar-divisor {
    width: 2px;
    height: 18px;
    padding: 0px;
    margin-left: 4px;
    margin-right: 4px;
    background-color: #ddd;
}

.jtoolbar .jtoolbar-label {
    padding-left: 8px;
    padding-right: 8px;
}



.jtoolbar-mobile a
{
    text-decoration:none;
    display:inline-block;
}

.jtoolbar-mobile i {
    display: inline-flex !important;
    color:#929292;
}

.jtoolbar-mobile span {
    font-size:0.7em;
    display:block;
    color:#929292;
}

.jtoolbar-mobile .jtoolbar-selected a, .jtoolbar-mobile .jtoolbar-selected i, .jtoolbar-mobile .jtoolbar-selected span {
    color:var(--jactive-color) !important;
    background-color:transparent;
}

.jtoolbar-item {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.jtoolbar-item i {
    display: block;
    color:#333;
}

.jtoolbar-item:hover {
    background-color:#f2f2f2;
}


.jtoolbar .jpicker {
    padding-left:0px;
    padding-right:0px;
}

.jtoolbar .jpicker-header {
    height: 24px;
    line-height: 24px;
    padding: 0px;
    padding-right: 20px;
    padding-left: 8px;
    background-position: top 50% right 0px;
    display: flex;
    align-items: center;
    font-size: 0.9em;
}

.jtoolbar .jpicker-content > div {
    padding: 6px;
}

.jtoolbar-active {
    background-color:#eee;
}

.jtoolbar .fa {
    width: 18px;
    height: 18px;
    display: block;
    line-height: 18px;
    font-size: 14px;
}

.jtoolbar .material-icons {
    font-size: 18px;
    width: 24px;
    height: 24px;
    display: block;
    line-height: 24px;
    transform: rotate(0.03deg);
    text-align: center;
}

.jtoolbar .jtoolbar-arrow {
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27black%27 width=%2718px%27 height=%2718px%27%3E%3Cpath d=%27M0 0h24v24H0z%27 fill=%27none%27/%3E%3Cpath d=%27M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z%27/%3E%3C/svg%3E");
    width: 24px;
    height: 16px;
    margin-left: 4px;
    border-left: 1px solid #f2f2f2;
}

.jtoolbar-floating {
    position: absolute;
    display: none;
    box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.2);
    border-radius: 4px;
    background-color: #fff;
    padding: 4px;
    z-index: 50;
    text-align: left;
    margin-right: 20px;
}

.jtoolbar-floating .jtoolbar-divisor {
    display: none;
}

.jtoolbar-arrow-selected .jtoolbar-floating {
    display: flex;
    flex-wrap: wrap;
}

